<template>
    <div>
        <modal :name="modal_name" class="final-modal" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">ADD NEW RECIPE</span>
                    <button type="button" class="closeModal" aria-label="Close" @click="hideAddRecipe()">
                        <span aria-hidden="true">
                            <i class="icon icon-close"></i>
                        </span>
                    </button>
                </div>
                <div class="v-modal-body pt-0 pb-0 pr-0" style="overflow-y: scroll !important;max-height: 420px">
                    <div class="v-modal-layout d-form">
                        <div class="mt-4 mb-4 mr-2">
                            <!-- <div class="d-flex flex-wrap justify-content-between align-items-center">
                                <span class="font-weight-bold text-black ml-3">Item Information</span>
                                <div class="d-flex">
                                    <img src="https://cdn.digicollect.com/cdn/posv2/scm/qr.svg" class="qr-logo-recipe" />
                                </div>
                            </div>
                            <div class="d-flex flex-wrap">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <span class="text-secondary fs-14">Product Name</span>
                                    <v-select name="product_name" v-validate="{required:true}" @search="loadProducts" v-model="selected_product" :options="products" label="name" placeholder="Search and Select Product" class="recipe-select"/>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('product_name')">{{ errors.first('product_name')}}</span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group pt-2">
                                        <span class="text-secondary fs-14">Category</span>
                                        <input name="category" style="padding-top:0px !important" v-model="category" readonly class="inputContact modal-bg" type="text" placeholder="Enter Category"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap mt-2">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group pt-2">
                                        <span class="text-secondary fs-14">Sub Category</span>
                                        <input name="sub_category" style="padding-top:0px !important" v-model="sub_category" readonly class="inputContact modal-bg" type="text" placeholder="Enter Sub Category"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group pt-2">
                                        <span class="text-secondary fs-14">Product Type</span>
                                        <input name="product_type" style="padding-top:0px !important" readonly v-model="product_type" class="inputContact modal-bg" type="text" placeholder="Enter Product Type"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                    </div>
                                </div>
                            </div>
                            <hr class="mt-4 ml-3 mr-3" style="border: solid 0.5px #a5a5a5;">  -->
                            <h6 class="mt-4 text-center text-secondary font-weight-bold">
                                {{productName}} <span v-if="variantName != undefined">- {{variantName}}</span>
                            </h6>
                            <span class="font-weight-bold text-black ml-3 mt-4 d-block">Ingredient Information</span>
                            <div class="d-flex flex-wrap mt-2">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group pt-2">
                                        <input name="recipe_name" v-model="recipe_name" v-validate="{required:true}" class="inputContact modal-bg" type="text" placeholder="Enter Recipe Name"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Recipe Name<sup>★</sup></label>
                                    </div>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('recipe_name')">{{ errors.first('recipe_name')}}</span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group pt-2">
                                        <input name="versions" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" v-validate="'required'" v-model="version" class="inputContact modal-bg" type="text" placeholder="Enter Version"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Versions<sup>★</sup></label>
                                    </div>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('versions')">Versions Is Required</span>
                                </div>
                            </div>
                            <div class="mt-2" v-for="(ingredient,index) in ingredients" :key="index">
                                <div class="d-flex flex-wrap ">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group pt-2">
                                            <span class="text-secondary fs-14" style="position: relative;">Ingredient Name<sup>★</sup></span>
                                            <multiselect :name="'ingredient_name'+index.toString()" v-validate="'required'" data-vv-as="Ingredient Name"
                                                v-model="ingredient.ingredient_name" class="digi-multiselect" style="bottom: 11px;" id="ajax" label="name"
                                                placeholder="Select Ingredient Name" :options="ingredientList"
                                                :multiple="false" :searchable="true" :loading="loadingIngredient"
                                                :internal-search="false" :clear-on-select="false" :close-on-select="true"
                                                :options-limit="40" :max-height="200" :show-no-results="true"
                                                :hide-selected="false" @select="resetVariant(ingredient)" @search-change="ingredientSearch">
                                                <template slot="tag" slot-scope="{ option, remove }">
                                                    <span class="custom__tag">
                                                        <span>{{ option.name }}</span>
                                                        <span class="custom__remove" @click="remove(option)">❌</span>
                                                    </span>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfIngredient" />
                                                </template>
                                                <span slot="noResult" class="font-weight-bold text-center d-block">No Results
                                                    Found.</span>
                                            </multiselect>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('ingredient_name'+index.toString())">{{ errors.first('ingredient_name'+index.toString())}}</span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group pt-2">
                                            <span class="text-secondary fs-14" style="position: relative;">Variant Name<sup>★</sup></span>
                                            <multiselect
                                                v-if="ingredient.ingredient_name.variant == true"
                                                :name="'variant_name'+index.toString()"
                                                :loading="loadingVariant"
                                                v-bind:rules="rules[index]" v-validate="'required'" data-vv-as="Variant Name"
                                                v-model="ingredient.variant_name" class="digi-multiselect" style="bottom: 11px;" id="ajax" label="variant_name"
                                                placeholder="Select Variant Name" :options="variant_options"
                                                :multiple="false" :searchable="true"
                                                :internal-search="false" :clear-on-select="false" :close-on-select="true"
                                                :options-limit="40" :max-height="200" :show-no-results="true"
                                                :hide-selected="false" @search-change="variantSearch">
                                                <template slot="tag" slot-scope="{ option, remove }">
                                                    <span class="custom__tag">
                                                        <span>{{ option.name }}</span>
                                                        <span class="custom__remove" @click="remove(option)">❌</span>
                                                    </span>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfVariant" />
                                                </template>
                                                <span slot="noResult" class="font-weight-bold text-center d-block">No Results
                                                    Found.</span>
                                            </multiselect>
                                            <multiselect
                                                v-else
                                                class="digi-multiselect" style="bottom: 11px;" id="ajax" label="variant_name"
                                                placeholder="Select Variant Name" :options="variant_options"
                                                :multiple="false" :searchable="true"
                                                :internal-search="false" :clear-on-select="false" :close-on-select="true"
                                                :options-limit="40" :max-height="200" :show-no-results="true"
                                                :hide-selected="false">
                                                <template slot="tag" slot-scope="{ option, remove }">
                                                    <span class="custom__tag">
                                                        <span>{{ option.name }}</span>
                                                        <span class="custom__remove" @click="remove(option)">❌</span>
                                                    </span>
                                                </template>
                                                <template slot="afterList">
                                                    <!-- <div v-observe-visibility="reachedEndOfList" /> -->
                                                </template>
                                                <span slot="noResult" class="font-weight-bold text-center d-block">No Results
                                                    Found.</span>
                                            </multiselect>
                                        </div>
                                        <span v-if="ingredient.ingredient_name.variant == true" class="invalid-feedback-form text-danger fs-13" v-show="errors.has('variant_name'+index.toString())">{{ errors.first('variant_name'+index.toString())}}</span>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap ">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="form-group pt-2">
                                            <input class="inputContact modal-bg" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" :name="'quantity'+index.toString()" v-validate="{required:true,min_value:1}" data-vv-as="Quantity" type="text" v-model.number="ingredient.quantity" placeholder="Enter Qty"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Quantity<sup>★</sup></label>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('quantity'+index.toString())">{{ errors.first('quantity'+index.toString())}}</span>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <span class="text-secondary fs-14" style="position: relative;top: 8px;">UM<sup>★</sup></span>
                                        <!-- <v-select v-model="ingredient.UM" @search="loadMeasurements" :options="measurements.map(measurement => {return {name: measurement.name,'_id': measurement._id}})" data-vv-as="UM" :name="'um'+index.toString()" label="name" v-validate="'required'" placeholder="Select UM" class="recipe-select max-select clearNone"/> -->
                                        <multiselect data-vv-as="UM" :name="'um'+index.toString()" v-validate="'required'"
                                            v-model="ingredient.UM" class="digi-multiselect" id="ajax" label="name"
                                            placeholder="Select UM" :options="measurements"
                                            :multiple="false" :searchable="true" :loading="isLoading"
                                            :internal-search="false" :clear-on-select="false" :close-on-select="true"
                                            :options-limit="40" :max-height="200" :show-no-results="true"
                                            :hide-selected="false" @search-change="loadMeasurementsSearch">
                                            <template slot="tag" slot-scope="{ option, remove }">
                                                <span class="custom__tag">
                                                    <span>{{ option.name }}</span>
                                                    <span class="custom__remove" @click="remove(option)">❌</span>
                                                </span>
                                            </template>
                                            <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfList" />
                                            </template>
                                            <span slot="noResult" class="font-weight-bold text-center d-block">No Results
                                                Found.</span>
                                        </multiselect>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('um'+index.toString())">{{ errors.first('um'+index.toString())}}</span>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="form-group pt-2">
                                            <input class="inputContact modal-bg" v-on:keypress="isNumber(event)" :name="'material_cost'+index.toString()" v-validate="{required:true}" data-vv-as="Material Cost" type="text" v-model.number="ingredient.material_cost" placeholder="Enter Material Cost"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Material Cost<sup>★</sup></label>
                                        </div>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('material_cost'+index.toString())">{{ errors.first('material_cost'+index.toString())}}</span>
                                    </div>
                                    <span v-if="index !=0" class="mt-3 d-block trashPosition" @click="deleteIngredient(index)">
                                        <i class="icon icon-trash text-secondary pointer"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap mt-4">
                                <div class="col-lg-10 col-md-10 col-sm-10">
                                    <span class="totalCost font-weight-bold">
                                        Total Material Cost: {{formatPrice(total_material_cost || 0)}}
                                    </span>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    <a class="white-space addGradient d-block pointer text-white pull-right" @click="addIngredients()">
                                        +Add New
                                    </a>
                                </div>
                            </div>
                            <hr class="mt-4 ml-3 mr-3" style="border: solid 0.5px #a5a5a5;">
                            <span class="font-weight-bold text-black ml-3 mt-4 d-block">Labor Cost Information</span>
                            <div class="d-flex flex-wrap mt-2" v-for="(labor,index) in labors" :key="index">
                                <!-- <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="form-group pt-2">
                                        <input class="inputContact modal-bg" @input="labor_cost(index)" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" :name="'cost_hour'+index.toString()" v-validate="{required:true,min_value:1}" data-vv-as="Cost/Hour" type="text" v-model.number="labor.cost_hour" placeholder="Enter Cost/Hour"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Cost/Hour<sup>★</sup></label>
                                    </div>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('cost_hour'+index.toString())">{{ errors.first('cost_hour'+index.toString())}}</span>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="form-group pt-2">
                                        <input class="inputContact modal-bg" @input="labor_cost(index)" type="text" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" :name="'no_of_hours'+index.toString()" v-validate="{required:true,min_value:1}" data-vv-as="No of Hours" v-model.number="labor.no_of_hours" placeholder="Enter No of Hours"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">No of Hours<sup>★</sup></label>
                                    </div>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('no_of_hours'+index.toString())">{{ errors.first('no_of_hours'+index.toString())}}</span>
                                </div> -->
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="form-group pt-2">
                                        <input class="inputContact modal-bg" @input="labor_cost(index)" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" :name="'cost_hour'+index.toString()" v-validate="{required:true}" data-vv-as="Cost/Hour" type="text" v-model.number="labor.cost_hour" placeholder="Enter Cost/Hour"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Cost/Hour<sup>★</sup></label>
                                    </div>
                                    <!-- <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('cost_hour'+index.toString())">{{ errors.first('cost_hour'+index.toString())}}</span> -->
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="form-group pt-2">
                                        <input class="inputContact modal-bg" @input="labor_cost(index)" type="text" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" :name="'no_of_hours'+index.toString()" v-validate="{required:true}" data-vv-as="No of Hours" v-model.number="labor.no_of_hours" placeholder="Enter No of Hours"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">No of Hours<sup>★</sup></label>
                                    </div>
                                    <!-- <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('no_of_hours'+index.toString())">{{ errors.first('no_of_hours'+index.toString())}}</span> -->
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="form-group pt-2">
                                        <span class="text-secondary fs-14">Labor Cost<sup>★</sup></span>
                                        <input class="inputContact modal-bg" readonly style="padding-top:0px !important" type="text" :name="'labor_cost'+index.toString()" v-validate="'required'" data-vv-as="Labor Cost" v-model.number="labor.labor_cost" placeholder="Enter Labor Cost"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <!-- <label class="labelContact">Labor Cost</label> -->
                                    </div>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('labor_cost'+index.toString())">{{ errors.first('labor_cost'+index.toString())}}</span>
                                </div>
                                <span v-if="index !=0" class="mt-3 d-block trashPosition" @click="deleteLabor(index)">
                                    <i class="icon icon-trash text-secondary pointer"></i>
                                </span>
                            </div>
                            <div class="d-flex flex-wrap mt-4">
                                <div class="col-lg-10 col-md-10 col-sm-10">
                                    <span class="totalCost font-weight-bold">
                                        Total Labor Cost: {{formatPrice(total_labor_cost)}}
                                    </span>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    <a class="white-space addGradient d-block pointer text-white pull-right" @click="addLabors()">
                                        +Add New
                                    </a>
                                </div>
                            </div>
                            <hr class="mt-4 ml-3 mr-3" style="border: solid 0.5px #a5a5a5;">
                            <div class="mt-4 d-flex ml-3">
                                <span class="text-secondary mr-2">Is this a Seasonal Product?</span>
                                <span class="mr-2">No</span>
                                <el-switch @change="showSeasonal" v-model="is_seasonal" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
                                <span class="ml-2">Yes</span>
                            </div>
                            <div class="d-flex flex-wrap mt-4" v-if="is_seasonal == true">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <span class="text-secondary fs-14">Select Start Month<sup>★</sup></span>
                                    <date-picker
                                        class="bg-input year-hide mx-calendar-panel-date"
                                        v-model="start_month"
                                        format="MM"
                                        :first-day-of-week="7"
                                        :lang="lang"
                                        :clearable="true"
                                        v-validate="'required'"
                                        name="start_month"
                                        type="month"
                                    ></date-picker>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('start_month')">Start Month is Required</span>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <span class="text-secondary fs-14">Select End Month<sup>★</sup></span>
                                    <date-picker
                                        class="bg-input year-hide mx-calendar-panel-date"
                                        v-model="end_month"
                                        format="MM"
                                        :first-day-of-week="7"
                                        :lang="lang"
                                        v-validate="'required'"
                                        name="end_month"
                                        :clearable="true"
                                        type="month"
                                    ></date-picker>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('end_month')">End Month is Required</span>
                                </div>
                            </div>
                            <div class="mt-4 d-flex ml-3" v-if="is_seasonal == true">
                                <span class="text-secondary mr-2 mt-1">Only Prepare On:<sup>★</sup></span>
                                <div class="form-check form-check-inline">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-validate="'required'" name="prepared_on" v-model="only_prepare_on" value="BreakFast" required />
                                    <span class="radio-icon"></span>
                                    <span class="form-check-description">Breakfast</span>
                                  </label>
                                </div>
                                <div class="form-check form-check-inline ml-2">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-validate="'required'" name="prepared_on" v-model="only_prepare_on" value="Lunch" required />
                                    <span class="radio-icon"></span>
                                    <span class="form-check-description">Lunch</span>
                                  </label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-validate="'required'" name="prepared_on" v-model="only_prepare_on" value="Dinner" required />
                                    <span class="radio-icon"></span>
                                    <span class="form-check-description">Dinner</span>
                                  </label>
                                </div>
                                <div class="form-check form-check-inline ml-2">
                                  <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-validate="'required'" name="prepared_on" v-model="only_prepare_on" value="All day" required />
                                    <span class="radio-icon"></span>
                                    <span class="form-check-description">All Day</span>
                                  </label>
                                </div>
                            </div>
                            <span class="invalid-feedback-form text-danger fs-13 ml-3" v-show="errors.has('prepared_on')">Only Prepare On is Required</span>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg">
                    <a class="btn btn-link btn-smm text-secondary btn-boxshadow" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hideAddRecipe()">CANCEL</a>
                    <button type="button" class="btn btn-secondary btn-smm" style="box-shadow: none;" @click="saveRecipe()">SAVE</button>
                    <!-- <button type="button" class="btn btn-secondary btn-smm disabled" style="box-shadow: none;" disabled v-if="!variantValidate">SAVE</button> -->
                </div>
            </div>
        </modal>
        <sweet-modal ref="success_modal" icon="success">{{success_msg}}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{warning_msg}}</sweet-modal>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import ScmServices from '../mixins/scm.js'
import { SweetModal } from 'sweet-modal-vue'
import DatePicker from "vue2-datepicker";
export default {
    props:['modal_name','variantId','productId','productName','variantName','product_Name'],
    mixins:[ScmServices],
    data(){
        return{
            search_product:'',
            category:'',
            sub_category:'',
            product_type:'',
            products:[],
            success_msg: null,
            warning_msg: null,
            value: 'https://example.com',
            size: 50,
            recipe_name:"",
            version: "",
            ingredientList:[],
            ingredients: [
                {
                    "UM": "",
                    "ingredient_name": "",
                    "material_cost": parseFloat(0),
                    "quantity": 0
                }
            ],
            labors: [
                {
                    "cost_hour": 0,
                    "labor_cost": 0,
                    "no_of_hours": 0
                },
            ],
            product_id:"",
            selected_product:"",
            is_seasonal: false,
            only_prepare_on:"",
            end_month: "",
            start_month: "",
            lang: {
                    type: "en",
                    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    months: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ],
                    pickers: [
                        "next 7 days",
                        "next 30 days",
                        "previous 7 days",
                        "previous 30 days"
                    ],
                    placeholder: {
                        date: "Select Date",
                        dateRange: "Select Date Range"
                    }
                },
                confirmText: {
                    type: String,
                    default: "APPLY"
                },
                measurements: [],
                isLoading: false,
                count: 0,
                limit: 10,
                unitMeasure: [],
                scrollable: false,
                variant_options:[],
                selected_ingredient: '',
                var_index: null,
                ingredientCheck:[],
                ingredientCount:0,
                loadingIngredient: false,
                scrollableIngredient: false,
                variantCheck:[],
                variantCount:0,
                loadingVariant: false,
                scrollableVariant: false,
            }
    },
    methods:{
        async resetVariant(ingredient){
            this.selected_ingredient = ingredient
            this.variant_options = []
            // setTimeout(() => {
                if(ingredient.ingredient_name.variant == true){
                    ingredient.variant_name = null
                }
                setTimeout(() => {
                    this.loadVariants()
                }, 100);
            // }, 100);
        },
        async variantSearch(searchVariant) {
            this.loadingVariant = true
            let response = await this.getVariants(
                {
                    product_id:this.selected_ingredient.ingredient_name._id,
                    search_key:searchVariant,
                    skip:0,
                    limit:10
                }
            );
            console.log(searchVariant)
            this.variant_options = response.variants
            this.variantCount = this.variant_options.length
            this.loadingVariant = false
            this.scrollableVariant = false
        },
        async reachedEndOfVariant(reached) {
            if (reached) {
                if (this.scrollableVariant == false) {
                    this.loadingVariant = true
                    let response = await this.getVariants(
                        {
                            "product_id":this.selected_ingredient.ingredient_name._id,
                            "skip":this.variantCount,
                            "limit":10
                        }
                    );
                    this.variantCheck = response.variants.length;
                    if (response.skip == this.variantCount) {
                        if (this.variantCheck > 0) {
                            let tempFiles = response.variants;
                            tempFiles.forEach(el => {
                                this.variant_options.push(el);
                            });
                            this.variantCount += response.variants.length;
                            this.loadingVariant = false
                        } else {
                            this.scrollableVariant = true
                            this.loadingVariant = false
                        }
                    }
                    this.loadingVariant = false
                }
            }
        },
        async loadVariants(){
            let response = await this.getVariants(
                {
                    "product_id":this.selected_ingredient.ingredient_name._id,
                    "skip":0,
                    "limit":10
                }
            );
            if(response.skip == this.variantCount){
                this.variant_options = response.variants
                this.variantCount = response.variants.length
            }
        },
        async ingredientSearch(searchIngredient) {
            this.loadingIngredient = true
            let response = await this.getIngredients(
                {
                    search_key:searchIngredient,
                    skip:0,
                    limit:10
                }
            );
            this.ingredientList = response.ingredients
            this.ingredientCount = this.ingredientList.length
            this.loadingIngredient = false
            this.scrollableIngredient = false
        },
        async reachedEndOfIngredient(reached) {
            if (reached) {
                if (this.scrollableIngredient == false) {
                    this.loadingIngredient = true
                    let response = await this.getIngredients(
                        {
                            "skip":this.ingredientCount,
                            "limit":6
                        }
                    );
                    this.ingredientCheck = response.ingredients.length;
                    if (response.skip == this.ingredientCount) {
                        if (this.ingredientCheck > 0) {
                            let tempFiles = response.ingredients;
                            tempFiles.forEach(el => {
                                this.ingredientList.push(el);
                            });
                            this.ingredientCount += response.ingredients.length;
                            this.loadingIngredient = false
                        } else {
                            this.scrollableIngredient = true
                            this.loadingIngredient = false
                        }
                    }
                    this.loadingIngredient = false
                }
            }
        },
        async loadIngredients(){
                let response = await this.getIngredients(
                    {
                        "skip":0,
                        "limit":10
                    }
                );
                if(response.skip == this.ingredientCount){
                    this.ingredientList = response.ingredients
                    this.ingredientCount = response.ingredients.length
                }
        },
        isNumber: function(evt,index) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(",", ".");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        showSeasonal(){
            this.only_prepare_on ="",
            this.end_month = "",
            this.start_month = ""
            if(!is_seasonal){
                this.is_seasonal = true;
                this.only_prepare_on ="",
                this.end_month = "",
                this.start_month = ""
            }
            else{
                this.is_seasonal = false;
                this.only_prepare_on ="",
                this.end_month = "",
                this.start_month = ""
            }
        },
        async loadProducts(search){
            let response = await this.getScmProducts(
                {
                    "search_key":search,
                    "skip":0,
                    "limit":10
                }
            );
            this.products = response.response
        },
        hideAddRecipe() {
            this.$modal.hide(this.modal_name);
            this.recipe_name="",
            this.version= "",
            this.ingredients= [
                {
                    "UM": "",
                    "ingredient_name": "",
                    "material_cost": 0,
                    "quantity": 0
                }
            ],
            this.labors= [
                {
                    "cost_hour": 0,
                    "labor_cost": 0,
                    "no_of_hours": 0
                },
            ],
            this.product_id="",
            this.selected_product="",
            this.is_seasonal= false,
            this.only_prepare_on="",
            this.end_month= "",
            this.start_month="",
            this.temp_ingredient_id="",
            this.count = 0,
            this.measurements = [],
            this.unitMeasure = [],
            this.isLoading = false,
            this.scrollable = false,
            this.ingredientCheck = [],
            this.loadingIngredient = false,
            this.ingredientCount = 0,
            this.ingredientList = [],
            this.scrollableIngredient = false,
            this.variant_options = [],
            this.selected_ingredient = '',
            this.loadMeasurements()
            this.loadIngredients()
        },
        addIngredients(){
            this.ingredients.push(
                {
                    "um_qty": "",
                    "cost_qty":"",
                    "ingredient_name": "",
                    "material_cost": parseFloat(0),
                    "quantity": 0
                }
            )
        },
        deleteIngredient(index){
            this.ingredients.splice(index,1)
        },
        addLabors(){
            this.labors.push(
                {
                    "cost_hour": 0,
                    "labor_cost": 0,
                    "no_of_hours": 0
                }
            )
        },
        deleteLabor(index){
            this.labors.splice(index,1)
        },
        async saveRecipe(){
            try {
                let validateStatus = await this.validateAllFields();
                if(validateStatus) {
                    this.new_recipe.ingredients.forEach((ingredient,index) => {
                        ingredient.ingredient_id = ingredient.ingredient_name._id
                        if(ingredient.ingredient_name.variant){
                            ingredient.variant_id = ingredient.variant_name.id
                        }
                        else{
                            ingredient.variant_id = ""
                        }
                        delete ingredient['ingredient_name']
                        delete ingredient['variant_name']
                    })
                    let response = await this.storeRecipe(this.new_recipe,this.productId);
                    let saveRecipe = response.recipe
                    this.$emit('saveRecipe',saveRecipe)
                    this.hideAddRecipe();
                }

            } catch (error) {
                this.$log.debug(error);
            }
        },
        async validateAllFields() {
            return new Promise((resolve,reject) => {
                this.$validator.validateAll().then(result => {
                    if(result == true){
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
            })
        },
        async loadMeasurementsSearch(search) {
            this.isLoading = true
            let response = await this.getScmMeasurements({
                search_key: search,
                skip: 0,
                limit: 10
            })
            this.measurements = response.unit_of_measurements
            this.count = this.measurements.length
            this.isLoading = false
            this.scrollable = false
        },
        async reachedEndOfList(reached) {
            this.isVisible = reached
            if (reached) {
                if (this.scrollable == false) {
                    this.isLoading = true
                    let response = await this.getScmMeasurements({
                        skip: this.count,
                        limit: 10
                    })
                    this.unitMeasure = response.unit_of_measurements.length;
                    if (response.skip == this.count) {
                        if (this.unitMeasure > 0) {
                            let tempFiles = response.unit_of_measurements;
                            tempFiles.forEach(el => {
                                this.measurements.push(el);
                            });
                            this.count += response.unit_of_measurements.length;
                            this.isLoading = false
                        } else {
                            this.scrollable = true
                            this.isLoading = false
                        }
                    }
                    this.isLoading = false
                }
            }
        },
        async loadMeasurements(search) {
            this.isLoading = true
            let response = await this.getScmMeasurements({
                search_key: search,
                skip: 0,
                limit: 10
            })
            this.unitMeasure = []
            if(response.skip == this.count){
                this.measurements = response.unit_of_measurements
                this.count = response.unit_of_measurements.length
            }
            this.isLoading = false
        },
        labor_cost(index){
            this.labors[index].labor_cost = this.labors[index].cost_hour * this.labors[index].no_of_hours
        }

    },
    components:{
        QrcodeVue,
        SweetModal,
        DatePicker
    },
    created(){
        const dict = {
            custom: {
                product_name:{
                    required: () => "Product Name Is Required",
                    regex: () => "Please Enter Valid Product Name",
                },
                versions: {
                    required: () => "Version Is Required",
                },
                category: {
                    required: () => "Category Is Required",
                    regex: () => "Please Enter Valid Category"
                },
                sub_category: {
                    required: () => "Sub Category Is Required",
                    regex: () => "Please Enter Valid Sub Category"
                },
                product_type: {
                    required: () => "Product Type Is Required",
                    regex: () => "Please Enter Valid Product Type"
                },
                ingredient_name:{
                    required: () => "ingredient Name Is Required",
                },
                quantity:{
                    required: () => "Quantity Is Required",
                    min_value: () => "Quantity value must contain minimum 1"
                },
                um:{
                    required: () => "UM Is Required",
                },
                material_cost:{
                    required: () => "Material Cost Is Required",
                    //min_value: () => "Material Cost value must contain minimum 1"
                },
                cost_hour:{
                    required: () => "Cost/Hour Is Required",
                    min_value: () => "Cost/Hour must contain minimum 1"
                },
                no_of_hours:{
                    required: () => "No of Hours Is Required",
                    min_value: () => "No of hours must contain minimum 1"
                },
                labor_cost:{
                    required: () => "Labor Cost Is Required",
                },
                recipe_name:{
                    required: () => "Recipe Name Is Required",
                },


            }

        }
        this.$validator.localize("en", dict);
    },
    mounted(){
        this.loadProducts();
        this.loadMeasurements('');
        this.loadIngredients();
    },
    computed:{
        new_recipe(){
            return {
                labors: this.labors,
                ingredients: this.ingredients,
                recipe_name: this.recipe_name,
                is_seasonal:this.is_seasonal,
                start_month:this.start_month,
                end_month:this.end_month,
                only_prepare_on:this.only_prepare_on,
                product_id: this.productId,
                variant_id: this.variantId,
                version: this.version
            }
        },
        total_material_cost() {
            return this.ingredients.reduce((total, ingredient) => total += parseFloat(ingredient.material_cost), 0)
        },
        total_labor_cost(){
            return this.labors.reduce((total, labor) => total += parseFloat(labor.labor_cost), 0)
        },
        rules() {
            return this.ingredients.map((ingredient, i) => {
                    if(ingredient.ingredient_name.variants){
                        return [  this.errors.first('variant_name' + i) || true
                        ];
                    }
                    else{
                        return [ false
                        ];
                    }
            })
        }
    },
    watch:{
        selected_product(newValue){
            this.category = newValue.category
            this.sub_category = newValue.sub_category
            this.product_type = newValue.product_type
        },
        // 'temp_ingredient_id'(newValue, oldvalue) {
        //     if (newValue) {
        //         this.ingredients.forEach((ingredient,index) =>{
        //             ingredient.ingredient_name = this.temp_ingredient_id._id
        //         })
        //     }
        // }
    }
}
</script>
<style scoped>
.border-radius{
    border-radius: 12px;
}
.quantity-details{
    flex: 1 1 250px;
    font-size: 16px;
    text-align: center;
}
.bx-shadow{
    box-shadow: 3px 0 0px -2px #989898;
}
.final-cost{
    border-top: solid 1px #989898;
    border-bottom: solid 1px #989898;
}
.qr-logo-recipe {
  height: 50px;
  margin-right: 40px;
  width: 50px;
  /* margin-left: px; */
}
.inputContact{
    border-bottom: solid 1px #a9aaad !important;
}
.addGradient{
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(109deg, #005dae -12%, #004fe3 89%);
    color: #fff;
    border-radius: 18px;
    padding: 6px 12px;
}
.totalCost{
    width: 500px;
    display: block;
    border-radius: 4px;
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.47);
    background-image: linear-gradient(to bottom, #d3ae7e, #ce5c43);
    text-align: center;
    padding: 5px;
}
.white-space{
    white-space: nowrap;
}
</style>